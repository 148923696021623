import axios from 'axios';
import React, { useState } from 'react';
import { useParams, useHistory,useLocation } from 'react-router-dom';
import { notification } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
 
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  
const openNotification = (type, message) => {
  notification.open({
    message: message,
    icon: null,
    className: `notification-${type}`, 
  });
};

export default function ResetPass() {
    const query = useQuery(); // Get the query parameters
    const token = query.get('token')
  const history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const [passwordInput, setPasswordInput] = useState({
    password: '',
    confirmPassword: '',
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setPasswordInput({ ...passwordInput, [e.target.name]: e.target.value });
  };

  const resetSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (passwordInput.password !== passwordInput.confirmPassword) {
      openNotification("error", "Passwords do not match");
      return;
    }

    const data = {
      password: passwordInput.password,
      token: token, // Pass the token from the URL
    };
     console.log(token)
    axios.post('/api/setPass', data, {
      headers: { 'Allowed-Source': allowedSource }
    })
    .then((res) => {
      if (res.data.status === 200) {
        openNotification("success", "Password reset successfully");
        history.push("/login");
      } else {
        openNotification("error", res.data.message);
      }
    })
    .catch((error) => {
      console.error("There was an error during the reset password request:", error);
    });
  };

  return (
    <div className="reset-container">
      <form onSubmit={resetSubmit} className="reset-form">
        <h1 className="reset-title">Reset Password</h1>

        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={handleInput}
            value={passwordInput.password}
          />
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            name="confirmPassword"
            onChange={handleInput}
            value={passwordInput.confirmPassword}
          />
          <h6 className="error-message">{passwordInput.error_list.password}</h6>
        </div>

        <button type="submit" className="submit-button">
          Reset Password
        </button>
      </form>
    </div>
  );
}
