import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const RoomDetails = () => {
  const { roomName } = useParams(); // Get room name from URL params
  const [roomData, setRoomData] = useState({ name: "", rent: "" }); // Initialize rent

  // Fetch room details when the component loads
  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await axios.post("/api/portal/admin/getRoom", { roomName });
        if (response.data.status === 200) {
          setRoomData({
            name: response.data.name,
            rent: response.data.rent || "", // Set rent from response if available
          });
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      }
    };

    fetchRoomDetails();
  }, [roomName]);

  // Handle updates to room data by setting the new values directly
  const handleInputChange = (e) => {
    setRoomData({
      ...roomData,
      [e.target.name]: e.target.value,
    });
  };

  // Update room details
  const handleUpdate = async () => {
    try {
      const data = {
        newName: roomData.name,
        newRent: roomData.rent, // Include newRent in the data object
        roomName,
      };

      const response = await axios.post("/api/portal/admin/setRoom", data, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.status === 200) {
        alert("Room details updated successfully!");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating room details:", error);
    }
  };

  return (
    <div className="roomdetails-container">
      <h2 className="roomdetails-heading">Room Details</h2>
      <form className="roomdetails-form">
        <div className="roomdetails-form-group">
          <label className="roomdetails-label">Room Name:</label>
          <input
            type="text"
            name="name" // Name matches the object key
            className="roomdetails-input"
            value={roomData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="roomdetails-form-group">
          <label className="roomdetails-label">Rent:</label>
          <input
            type="number" // Input type for rent
            name="rent" // Name matches the object key
            className="roomdetails-input"
            value={roomData.rent}
            onChange={handleInputChange}
          />
        </div>
    
        <button
          type="button"
          className="roomdetails-button"
          onClick={handleUpdate}
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default RoomDetails;
