import React, { useState } from 'react';
import axios from 'axios';
import { message, Button, Input, Table } from 'antd';
 

const HistoryLookup = () => {
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [phn_no, setPhnNo] = useState('');
    const [data, setData] = useState([]);
   // const [totalPaid, setTotalPaid] = useState(0);
   // const [totalDue, setTotalDue] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const handleGetResult = async () => {
        try {
            const response = await axios.post('/api/portal/admin/getDetails', {
                month,
                year,
                phn_no
            });
            if (response.data.status === 200) {
                setData(response.data.data);
              //  setTotalPaid(response.data.totalPaid);
               // setTotalDue(response.data.totalDue);
            } else {
                message.error('Failed to fetch data');
            }
        } catch (error) {
            console.error(error);
            message.error('Error fetching data');
        }
    };

    const filteredData = data.filter(record => 
        record.roomName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPaid = filteredData.reduce((sum, record) => sum + record.paid, 0);
    const totalDue = filteredData.reduce((sum, record) => sum + record.due, 0);
    return (
        <div className="history-lookup-container">
        <h2>History Lookup</h2>
        <br />
        <div className="input-container">
            <div>
                <h5>Month</h5>
                <Input 
                    className="input-field"  // Added class for styling
                    placeholder="Month" 
                    type="number" 
                    value={month} 
                    onChange={e => setMonth(Number(e.target.value))} 
                />
            </div>
            <div>
                <h5>Year</h5>
                <Input 
                    className="input-field"  // Added class for styling
                    placeholder="Year" 
                    type="number" 
                    value={year} 
                    onChange={e => setYear(Number(e.target.value))} 
                />
            </div>
            <div>
                <h5>Phn No</h5>
                <Input 
                    className="input-field"  // Added class for styling
                    placeholder="Phone Number" 
                    value={phn_no} 
                    onChange={e => setPhnNo(e.target.value)} 
                />
            </div>
            <Button type="primary" onClick={handleGetResult}>Get Result</Button>
        </div>
    
        <div className="search-container" style={{
        maxWidth: '320px',
        margin: '0 auto',      // Center horizontally
        display: 'flex',
        justifyContent: 'center'
    }}>
                <Input
                    placeholder="Search by Room Name"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </div>

        <table className="data-table">
            <thead>
                <tr>
                    <th>Room</th>
                    <th>Rent</th>
                    <th>Paid</th>
                    <th>Due</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {filteredData.map((record, index) => (
                    <tr key={index}>
                        <td>{record.roomName}</td>
                        <td>{record.paid+record.due}</td>
                        <td>{record.paid}</td>
                        <td>{record.due}</td>
                        <td>{record.month}/{record.year }</td>
                    </tr>
                ))}
            </tbody>
        </table>
    
        {/* Total Paid and Total Due */}
        <div className="total-container">
            <h4>Total Paid: {totalPaid}</h4>
            <h4>Total Due: {totalDue}</h4>
        </div>
    </div>
    
    );
};

export default HistoryLookup;
