import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import MasterLayout from './layouts/admin/MasterLayout';
import axios from 'axios';
import swal from 'sweetalert';
import {useHistory} from 'react-router-dom';

export default function AdminPrivateRoute(props) {
    const history = useHistory();

    const [Authenticated, setAuthenticated] = useState(false);
    const [Loading, setLoading] = useState(true);

    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
   const data = {}
    useEffect(() => {
      axios.post("/api/checkAuthenticity",data,{
        headers: { 'Allowed-Source': allowedSource }
    })
      .then(res=>{
          
           if(res.status===200){
            setAuthenticated(true);
           }

           setLoading(false);
      })
      .catch(error => {
        // console.log(error)
        setAuthenticated(false);
        setLoading(false);
      });
    
      return ()=>{
        setAuthenticated(false);
       }

    }, []);



  
  axios.interceptors.response.use(function (response) {
   
    return response;
  }, function (error) {
  
  
    swal("Warning", "You are not authorized","warning");
    return Promise.reject(error);
  });
  
 

    
    if(Loading){
        return <h1>Loading..</h1>
    }
    else{

  return (
    
   <Route 
   path={props.path}
   name = {props.name}
   render={(props,location)=>
       Authenticated ?
      (<MasterLayout {...props}/> ) :
      (<Redirect to={ {pathname:"/login",state:{from:location}}} />)
   }
   
   /> 
  )

}



}
