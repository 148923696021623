import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notification } from 'antd';

const openNotification = (type, message) => {
    notification.open({
        message: message,
        icon: null, // No icon
        className: `notification-${type}`, // Optional: Custom class for styling
    });
};


export default function CreateRoom() {
  const [roomName, setRoomName] = useState('');
  const [rent, setRent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate inputs
    if (!roomName || !rent || isNaN(rent) || rent <= 0) {
        toast.error("Please provide valid room name and rent");
    
      return;
    }

    // Post request to create a room
    try {
      const response = await axios.post('/api/portal/admin/createRoom', {
        name: roomName,
        rent: Number(rent),
      });

      if (response.data.status === 200) {
        openNotification('success', response.data.message);
       
        setRoomName('');  // Reset fields
        setRent('');
      } else {
        openNotification('error', response.data.message);
        //toast.error(response.data.message || "Error");
         
      }
    } catch (error) {
        toast.error(error.response?.data?.message);
      
    }
  };

  return (
    <div className="createRoom-container">
      <h2 className="createRoom-title">Create Room</h2>
      <form onSubmit={handleSubmit} className="createRoom-form">
        <div className="createRoom-formGroup">
          <label htmlFor="roomName">Room Name:</label>
          <input
            type="text"
            id="roomName"
            className="createRoom-input"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </div>

        <div className="createRoom-formGroup">
          <label htmlFor="rent">Rent:</label>
          <input
            type="number"
            id="rent"
            className="createRoom-input"
            value={rent}
            onChange={(e) => setRent(e.target.value)}
          />
        </div>

        <button type="submit" className="createRoom-submitBtn">Submit</button>
      </form>
    </div>
  );
}
