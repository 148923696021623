import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { notification } from 'antd';

const openNotification = (type, message) => {
    notification.open({
        message: message,
        icon: null, // No icon
        className: `notification-${type}`, // Optional: Custom class for styling
    });
};

export default function ForgotPass(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();

  const [loginInput, setloginInput] = useState({
    email:'',
    error_list:[],
});


const handleInput = (e)=>{
   e.persist();
   setloginInput({...loginInput,[e.target.name]:e.target.value});
}


const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
        email: loginInput.email,
        password: loginInput.password
    };

    axios.post('/api/resetPass', data, {
        headers: { 'Allowed-Source': allowedSource }
    })
    .then((res) => {
        if (res.data.status === 200) {
             
            openNotification("success","Email Sent")
            history.push("/home")
        } 
        else  {
            openNotification("error",res.data.message)
           
        } 
        
    })
    .catch((error) => {
        console.error("There was an error during the login request:");
       
    });
};



 

  return (
<div className="login-container">
  <form onSubmit={loginSubmit} className="login-form">
    <h1 className="login-title">Login</h1>
    
    <div className="form-group">
      <label>Email address</label>
      <input
        type="email"
        className="form-control"
        name="email"
        onChange={handleInput}
        value={loginInput.email}
      />
      <h6 className="error-message">{loginInput.error_list.email}</h6>
    </div>
    
    <button type="submit" className="submit-button">
      Send Link
    </button>
  </form>
</div>

  
  )
}
