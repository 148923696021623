import React from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logo.jpg';




 



export default function NavBar(props) {
    const [Show, setShow] = useState(false);



    // useEffect(() => {
    //     if(localStorage.getItem('auth_token')){
    //         props.setloggedIn(true);
    //     }
    //     else
    //     props.setloggedIn(false);
             
    //       let name = localStorage.getItem("auth_name");
           
       
       
           
    //   }, []);


       

  return (
    <div className='NavBar '>


    <div className='d-flex justify-content-between align-items-center NavBar'>
      <Link className='dec-none  text-black' to="/home"><img className='logo' src={logo} alt='none'/></Link>
     
     

      <div className='d-flex align-items-center '>
             
            {!props.loggedIn &&
                <div className='d-flex align-items-center '>
                <Link className='btn  mx-2 dec-none text-white login' to="/login">Login</Link>
                <Link className='btn  mx-2 dec-none text-white register' to="/register">Register</Link>
              </div>
            } 
            { props.loggedIn &&
                <button onClick={(e)=>props.handleLogout(e)} type="button" className="m-1 btn btn-danger border border-1">Logout</button>
            }
            {props.isAdmin &&
              <>
               <Link className='btn  mx-2 dec-none text-white login' to="/admin">Admin</Link>
              </>

            }

        </div>   
   


    </div>



  
  





  </div>
  )
}
