import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import swal from 'sweetalert';

export default function Login(props) {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();

  const [loginInput, setloginInput] = useState({
    email:'',
    password:'',
    error_list:[],
});


const handleInput = (e)=>{
   e.persist();
   setloginInput({...loginInput,[e.target.name]:e.target.value});
}


const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
        email: loginInput.email,
        password: loginInput.password
    };

    axios.post('/api/login', data, {
        headers: { 'Allowed-Source': allowedSource }
    })
    .then((res) => {
        if (res.data.status === 200) {
     
            window.location.href = res.data.redirect;  
        } 
        else if (res.data.status === 201) {
         
            swal("Warning", res.data.message, 'warning');
        } 
        else {
        
            setloginInput({ ...loginInput, error_list: res.data.errors });
        }
    })
    .catch((error) => {
        console.error("There was an error during the login request:");
       
    });
};



 

  return (
   <div className="Login my-5">
      <form className="card" onSubmit={loginSubmit}>
        <h1>Login</h1>
     
        <div className=" mb-3 w-75 m-auto">
          <label className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            name="email"
            onChange={handleInput}
            value={loginInput.email}
          />
          <h6>{loginInput.error_list.email}</h6>
        </div>
        <div className=" mb-3 w-75 m-auto">
          <label  className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            name="password"
            onChange={handleInput}
            value={loginInput.password}
          />
          <h6>{loginInput.error_list.password}</h6>
        </div>
       
        <Link to='/forgotpass' >Forgot Password?</Link>
        <button type="submit" className="btn btn-primary  w-25 m-auto">
          Login
        </button>
      </form>
    </div>
  )
}
