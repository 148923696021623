
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
 
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import { useEffect, useState } from 'react';

import NavBar from './components/frontend/NavBar';
import Home from './components/frontend/Home';
import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import axios from 'axios';
import swal from 'sweetalert';
import AdminPrivateRoute from './AdminPrivateRoute';


import {useHistory} from 'react-router-dom';

import Verify from './components/frontend/Verify';
import VerifyEmail from './components/frontend/VerifyEmail';
import VerifyLogin from './components/frontend/VerifyLogin';
import ForgotPass from './components/ForgotPass';
import ResetPass from './components/ResetPass';

axios.defaults.baseURL = 'https://zihan-back.onrender.com';
// axios.defaults.baseURL = 'http://localhost:8000';
//axios.defaults.baseURL = '';
const front_url = 'zihan.alamgirenterprise.com'

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function(config){
 const token = localStorage.getItem('zihan_token');
 config.headers.Authorization = token ? `${token}` : '';
 return config;
});


function App() {
  
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();
const [loggedIn, setloggedIn] = useState(false);

const [cartLength, setCartLength] = useState(0);
const [isAdmin,setAdmin] = useState(false);

const handleLogout = (e) => {
   

  const data = {};
  axios.post('/api/logout', data, {
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res => {
      if (res.data.status === 200) {
          setAdmin(false);
          localStorage.removeItem('zihan_token');
          localStorage.removeItem('zihan_name');
          setloggedIn(false);
         
         window.location.href = `/home`
         // window.location.reload(); // Reload the page after logging out
      }
  })
  .catch(err => {
      console.log(err);  // Handle the error properly
  });
};


axios.interceptors.response.use(
  function(response){
  return response;
},function (error){
  localStorage.removeItem('zihan_token');
  localStorage.removeItem('zihan_name');
  setloggedIn(false)
  setAdmin(false);
}

);

useEffect(() => {
  console.log(isAdmin);

  let token = localStorage.getItem("zihan_token");

  const data = {token};
  axios.post('/api/loggedIn', data, {
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res => {
      if (res.data.status === 200) {
          
        setloggedIn(true);
        setAdmin(true);
      }
  })
  .catch(err => {
    localStorage.removeItem('zihan_token');
    localStorage.removeItem('zihan_name');
    setloggedIn(false);
    setAdmin(false)
      console.log(err);  // Handle the error properly
  });
  /////////////

   
   

}, [])



  return (
  
    <Router>
    <div className="App ">
       
      

        

        

               <div className='innerContainer d-flex flex-column '>
               <NavBar isAdmin={isAdmin}  handleLogout={handleLogout} loggedIn={loggedIn} setloggedIn={setloggedIn} setCartLength={setCartLength}/> 
              <div className='contents'>
                <Switch>
                     
                       
                        <Route exact path="/home" >
                           <Home loggedIn={loggedIn} setloggedIn={setloggedIn} setAdmin={setAdmin} isAdmin={isAdmin}  /> 
                        </Route>

                        <Route path='/login'>
                          {localStorage.getItem('auth_token') ? <Redirect to="/" />: <Login setloggedIn={setloggedIn} setAdmin={setAdmin} />}
                        </Route>

                        <Route path='/register'>
                          {localStorage.getItem('auth_token') ? <Redirect to="/" />: <Register setloggedIn={setloggedIn} />}
                        </Route>

                        <Route exact path='/verifyEmail/:token'>
                           <VerifyEmail setloggedIn={setloggedIn} />
                        </Route>

                        <Route exact path='/verifyLogin/:token'>
                           <VerifyLogin setloggedIn={setloggedIn} setAdmin={setAdmin}/>
                        </Route>

                        <Route exact path="/verify/:email/:token" >
                        <Verify/>
                      </Route>
                      
                        <Route exact path='/resetPass'>
                           <ResetPass  />
                        </Route>
                      
                     

                      <Route exact path="/forgotpass" >
                        <ForgotPass/>
                      </Route>

                      
                      <Redirect exact from="/" to="/home"/>

                      <AdminPrivateRoute path="/admin" name="Admin" /> 

                </Switch>

                </div>
           

                </div>

                
             

    </div>

       
    </Router>
  
  );
}

export default App;
