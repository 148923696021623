import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
 
import loader from '../../images/loading.gif'
 
 

 
import ReactPixel from 'react-facebook-pixel';
import {useHistory} from 'react-router-dom';



export default function Home(props) {
  ReactPixel.pageView();
  const history = useHistory();
 
   const [loading, setloading] = useState(true);

   useEffect(() => {
     
    setloading(false);
  
   },[])

  

if(loading){
  return(
    <div >
      <img  src={loader}   alt="Your GIF"/> 
      
    </div>

  )
}
else{
  return (
    <div className='home-wrapper'>
            

           

           
             
              
    </div>
  )
}
  
}
