import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message, Button, Input, Select, Modal } from 'antd';
import { Link } from 'react-router-dom';

const RoomDetails = () => {
    const [rooms, setRooms] = useState([]);
    const [newTenant, setNewTenant] = useState({
        name: '',
        NID: '',
        hometown: '',
        phn_no: '',
        roomName: '',
        rent: ''
    });
    const [paymentDetails, setPaymentDetails] = useState({
        roomName: '',
        tenantName: '',
        NID: '',
        phn_no: '',
        rent: '',
        paid: '',
        month: '',
        year: ''
    });
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [currentTenant, setCurrentTenant] = useState({
        name: '',
        NID: '',
        hometown: '',
        phn_no: '',
        rent: '',
        roomName: ''
    });

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await axios.get('/api/portal/admin/getRooms');
            if (response.data.status === 200) {
                setRooms(response.data.data);
            } else {
                message.error('Failed to fetch rooms');
            }
        } catch (error) {
            console.error(error);
            message.error('Error fetching rooms');
        }
    };

    const handleAddTenant = async () => {
        try {
            const response = await axios.post('/api/portal/admin/addTenant', {
                roomName: newTenant.roomName,
                ...newTenant
            });
            if (response.data.status === 200) {
                message.success('Tenant added successfully');
                fetchRooms();
                setNewTenant({ name: '', NID: '', hometown: '', phn_no: '', roomName: '', rent: '' });
            } else {
                message.error('Failed to add tenant');
            }
        } catch (error) {
            console.error(error);
            message.error('Error adding tenant');
        }
    };

    const confirmRemoveTenant = (roomId, NID) => {
        Modal.confirm({
            title: 'Are you sure you want to remove this tenant?',
            content: 'Once removed, this tenant will no longer be associated with this room.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleRemoveTenant(roomId, NID),
            onCancel: () => console.log('Remove canceled'),
        });
    };

    const handleRemoveTenant = async (roomId, NID) => {
        try {
            const response = await axios.post('/api/portal/admin/removeTenant', {
                data: { roomId, NID }
            });
            if (response.data.status === 200) {
                message.success('Tenant removed successfully');
                fetchRooms();
            } else {
                message.error('Failed to remove tenant');
            }
        } catch (error) {
            console.error(error);
            message.error('Error removing tenant');
        }
    };

    const openPaymentModal = (roomName, tenant) => {
        setPaymentDetails({
            roomName,
            tenantName: tenant.name,
            NID: tenant.NID,
            phn_no: tenant.phn_no,
            rent: tenant.rent,
            paid: '',
            month: '',
            year: ''
        });
        setIsPaymentModalVisible(true);
    };

    const handlePayment = async () => {
        try {
            const response = await axios.post('/api/portal/admin/addRent', {
                roomName: paymentDetails.roomName,
                tenantName: paymentDetails.tenantName,
                NID: paymentDetails.NID,
                phn_no: paymentDetails.phn_no,
                rent: paymentDetails.rent,
                paid: Number(paymentDetails.paid),
                month: paymentDetails.month,
                year: paymentDetails.year
            });
            if (response.data.status === 200) {
                message.success('Payment processed successfully');
                setIsPaymentModalVisible(false);
                setPaymentDetails({ roomName: '', tenantName: '', NID: '', phn_no: '', rent: '', paid: '', month: '', year: '' });
            } else {
                message.error('All Fields Are Required');
            }
        } catch (error) {
            console.error(error);
            message.error('Error processing payment');
        }
    };

    const openUpdateModal = (tenant, roomName) => {
        setCurrentTenant({ ...tenant, roomName });
        setIsUpdateModalVisible(true);
    };

    const handleUpdateTenant = async () => {
        try {
            const response = await axios.post('/api/portal/admin/updateTenant', {
                roomName: currentTenant.roomName,
                name: currentTenant.name,
                NID: currentTenant.NID,
                hometown: currentTenant.hometown,
                phn_no: currentTenant.phn_no,
                rent: currentTenant.rent
            });
            if (response.data.status === 200) {
                message.success('Tenant updated successfully');
                setIsUpdateModalVisible(false);
                fetchRooms();

            } else {
                message.error('Failed to update tenant');
            }
        } catch (error) {
            console.error(error);
            message.error('Error updating tenant');
        }
    };

    const [searchQuery, setSearchQuery] = useState("");

    // Handle input change for search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter rooms based on the search query
    const filteredRooms = rooms.filter(room => 
        room.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="room-details-container" style={{ padding: '20px' }}>
        <h2>Room Details</h2>
    
        {/* Add Tenant Section */}
        <div className="add-tenant" style={{ marginTop: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}>
            <h4>Add Tenant:</h4>
            <Input
                placeholder="Tenant Name"
                value={newTenant.name}
                onChange={e => setNewTenant({ ...newTenant, name: e.target.value })}
            />
            <Input
                placeholder="NID"
                value={newTenant.NID}
                onChange={e => setNewTenant({ ...newTenant, NID: e.target.value })}
            />
            <Input
                placeholder="Hometown"
                value={newTenant.hometown}
                onChange={e => setNewTenant({ ...newTenant, hometown: e.target.value })}
            />
            <Input
                placeholder="Phone No."
                value={newTenant.phn_no}
                onChange={e => setNewTenant({ ...newTenant, phn_no: e.target.value })}
            />
            <Input
                placeholder="Rent"
                type="number"
                value={newTenant.rent}
                onChange={e => setNewTenant({ ...newTenant, rent: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Select
                placeholder="Select Room"
                value={newTenant.roomName}
                onChange={value => setNewTenant({ ...newTenant, roomName: value })}
                style={{ width: '100%', marginTop: '10px' }}
            >
                {rooms.map(room => (
                    <Select.Option key={room._id} value={room.name}>
                        {room.name}
                    </Select.Option>
                ))}
            </Select>
            <Button
                type="primary"
                onClick={handleAddTenant}
                style={{ marginTop: '10px' }}
            >
                Add Tenant
            </Button>
        </div>
    
        <div>
        <input 
                type="text"
                placeholder="Search Room Name"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '300px', marginBottom: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
            />
</div>
        {/* Room Details Section */}
        {filteredRooms.map(room => (
            <div key={room._id} className="room" style={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: '5px', padding: '10px' }}>
                {/* Top Div: Room Name and Rent */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                    <h3 style={{ margin: 0 }}>{room.name}</h3>
                    <h3 style={{ margin: 0 }}>Rent: {room.rent}</h3>
                </div>
                {/* Bottom Div: Tenants List */}
                <div className="tenants-list">
                    <h4>Tenants:</h4>
                    {room.tenants.map(tenant => (
                        <div key={tenant.NID} className="tenant" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '10px', borderBottom: '1px solid #ddd' }}>
                            <div className='text-start' style={{ flexGrow: 1 }}>
                                <h6 style={{ margin: '5px 0' }}>Name: {tenant.name}</h6>
                                <h6 style={{ margin: '5px 0' }}>NID: {tenant.NID}</h6>
                                <h6 style={{ margin: '5px 0' }}>Phone: {tenant.phn_no}</h6>
                                <h6 style={{ margin: '5px 0' }}>Rent: {tenant.rent}</h6>
                            </div>
                            <div className="tenant-buttons" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
                                <Button style={{
                                    border: '0px',
                                    borderRadius: '10%',
                                    backgroundColor: 'rgba(79,186,212,255)'
                                    }}
                                    type=""
                                    onClick={() => openPaymentModal(room.name, tenant)}
                                >
                                    Pay
                                </Button>
                                <Button style={{ 

                                 border: '0px',
                                    borderRadius: '10%',
                                    backgroundColor: 'rgb(243,93,93)'
                                    }}
                                    type="danger"
                                    onClick={() => confirmRemoveTenant(room._id, tenant.NID)}
                                >
                                    Remove
                                </Button>
                                <Button style={{ 

                                    border: '0px',
                                    borderRadius: '10%',
                                    backgroundColor: 'rgb(238,189,75)'
                                    }}
                                    type="default"
                                    onClick={() => openUpdateModal(tenant, room.name)}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
                <Link to={`/admin/updateRoom/${room.name}`} className='dec-none btn btn-warning' style={{ marginTop: '10px' }}>Update Room</Link>
            </div>
        ))}
    
        {/* Payment Modal */}
        <Modal
            title={`Pay Rent for ${paymentDetails.tenantName}`}
            visible={isPaymentModalVisible}
            onCancel={() => setIsPaymentModalVisible(false)}
            footer={[
                <Button key="cancel" onClick={() => setIsPaymentModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="pay" type="primary" onClick={handlePayment}>
                    Pay
                </Button>
            ]}
        >
            <Input
                placeholder="Amount Paid"
                type="number"
                value={paymentDetails.paid}
                onChange={e => setPaymentDetails({ ...paymentDetails, paid: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="Month"
                type="number"
                value={paymentDetails.month}
                onChange={e => setPaymentDetails({ ...paymentDetails, month: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="Year"
                type="number"
                value={paymentDetails.year}
                onChange={e => setPaymentDetails({ ...paymentDetails, year: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
        </Modal>
    
        {/* Update Modal */}
        <Modal
            title={`Update Tenant Details`}
            visible={isUpdateModalVisible}
            onCancel={() => setIsUpdateModalVisible(false)}
            footer={[
                <Button key="cancel" onClick={() => setIsUpdateModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="update" type="primary" onClick={handleUpdateTenant}>
                    Update
                </Button>
            ]}
        >
            <Input
                placeholder="Tenant Name"
                value={currentTenant.name}
                onChange={e => setCurrentTenant({ ...currentTenant, name: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="NID"
                value={currentTenant.NID}
                onChange={e => setCurrentTenant({ ...currentTenant, NID: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="Hometown"
                value={currentTenant.hometown}
                onChange={e => setCurrentTenant({ ...currentTenant, hometown: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="Phone No."
                value={currentTenant.phn_no}
                onChange={e => setCurrentTenant({ ...currentTenant, phn_no: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
            <Input
                placeholder="Rent"
                type="number"
                value={currentTenant.rent}
                onChange={e => setCurrentTenant({ ...currentTenant, rent: e.target.value })}
                style={{ marginBottom: '10px' }}
            />
        </Modal>
    </div>
    
    );
};

export default RoomDetails;
