import React, { useEffect } from 'react'
import {BrowserRouter as Router,Route,Switch, BrowserRouter,Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom';
 
import routes from '../../routes/routes';
import { useState } from 'react';
import menu from '../../images/menu.png';
import close from '../../images/close.png';
 

export default function MasterLayout() {

  const [Show, setShow] = useState(false);
  const [user, setUser] = useState("");

  const toggleSidebar = ()=>{
    setShow(!Show);
  }
 
  useEffect(() => {
  
      let name = localStorage.getItem("zihan_name");
      if(name!==null)
        setUser(name)
  }, []);


  return (

 
    <div className='master admin-bg text-white'>

          

          <nav className="admin-sidebar-btn d-flex flex-wrap justify-content-between">
     <button onClick={()=>toggleSidebar()}  className=' admin-btn d-flex'>
     <img className='admin-sidebar-open  my-2' src={menu} alt='Open'></img>
     <h3 className='mx-3 mt-3 text-white'>Welcome,{user}</h3>
     </button>
   
     <div className='d-flex flex-wrap align-items-center justify-content-center dash-button'>
     
     
     </div>
    </nav>
        

    <div className={`admin-sidebar text-start d-flex flex-column  ${Show ? 'sidebar-show' : ''}`}>
           <div className='text-center w-100'>
              <button onClick={()=>toggleSidebar()} className='m-3 admin-btn text-center'>
                    <img className='admin-sidebar-close ' src={close} alt='Close'></img>
              </button>
            </div>  
             
             <div onClick={(e)=>setShow(false)}>
            <Link to='/admin/dashboard' className='dec-none navItem' >Dashboard</Link>
            </div>

                 
                  
                  <h3>Rooms</h3>
                  <div onClick={(e)=>setShow(false)}>
                  <Link to='/admin/createRoom' className='dec-none navItem'>Create Room</Link>
                  </div>
                  <div onClick={(e)=>setShow(false)}>
                  <Link to='/admin/Rooms' className='dec-none navItem'>Rooms and Payment</Link>
                  </div>
                  <h3>History</h3>
                  <div onClick={(e)=>setShow(false)}>
                  <Link to='/admin/history' className='dec-none'>Get History</Link>
                  </div>

                 
                  
                
                 
          
         
  </div>


        <div className='content '>
           
           

           <main className='w-100 text-black'>
            
            <Switch>
                { 
                routes.map((route,index)=>{


                 return(
                     route.component &&
                     <Route
                     key={index}
                     path={route.path}
                     name={route.name}
                     exact = {route.exact}
                     render={(props)=>(
                           <route.component {...props} user={user}/> 
                      )}
                     />
                 ) 

                })


                }
                <Redirect from="/admin" to="/admin/dashboard"/>

                

            </Switch>
 
            

           </main>
 

        </div>
       







    </div>
 
  )
}
