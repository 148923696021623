import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';


const VerifyEmail = () => {
    const [otp, setOtp] = useState(''); // State for OTP input
    const { token } = useParams(); // Extract the token from the URL

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            otp: otp,
            token: token, // Send the token in the body
        };

        axios.post('/api/verifyEmail', data)
            .then(res => {
                if (res.data.status===202) {
                    swal("Warning",res.data.message,'warning');
                }
                else{
                    alert(res.data.message);
                       
                   
                    window.location.href = res.data.redirect;
                } 
                
            })
            .catch(error => {
               // console.error("There was an error verifying the email!", error);
            });
    };

    return (
        <div className="verifyEmail-container">
            <div className="verifyEmail-card">
                <h2 className="verifyEmail-title">Verify Your Email</h2>
                <form onSubmit={handleSubmit}>
                    <div className="verifyEmail-form-group">
                        <label htmlFor="otp" className="verifyEmail-label">Enter OTP</label>
                        <input
                            type="text"
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            className="verifyEmail-input"
                        />
                    </div>
                    <button type="submit" className="verifyEmail-submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default VerifyEmail;
