

import CreateRoom from "../components/admin/CreateRoom";
import Dashboard from "../components/admin/Dashboard";
import HistoryLookup from "../components/admin/History";
import RoomDetails from "../components/admin/RoomDetails";

import Rooms from "../components/admin/Rooms";



const routes = [
 {path:'/admin' ,exact:true,name:"Admin",component:Dashboard},
 {path:'/admin/dashboard' ,exact:true,name:"Dashboard",component:Dashboard},
 {path:'/admin/createRoom' ,exact:true,name:"createRoom",component:CreateRoom},
 {path:'/admin/Rooms' ,exact:true,name:"Rooms",component:Rooms},
 {path:'/admin/history' ,exact:true,name:"HistoryLookup",component:HistoryLookup},
 {path:'/admin/updateRoom/:roomName' ,exact:true,name:"RoomDetails",component:RoomDetails},
]


export default routes;